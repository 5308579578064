<template>
  <div>
    <MbnTableTools
      :search="search"
      :limit="limit"
      @updateSearchQuery="search = $event"
      @updateLimit="updateLimit"
      @resetSearch="resetSearch"
    />

    <CRow>
      <CCol>
        <CCard>
          <CCardHeader class="d-flex align-items-center justify-content-between">
            <span>{{ $t('sidebar.advertisers') }}</span>
          </CCardHeader>
          <CCardBody>
            <MbnErrorMessage v-if="error" />

            <CDataTable
              v-else
              :fields="fields"
              :items="tableItems"
              striped
              add-table-classes="section-table"
              :no-items-view="{ noItems: $t('ui.no_items_available') }"
              sorter
              clickable-rows
              :loading="isLoading"
              hover
              @row-clicked="edit"
            >
              <template #approvedSites="{ item }">
                <td>
                  <CListGroup>
                    <CListGroupItem v-for="(site, index) in item.approvedSites" :key="index" class="table-list-group">
                      {{ site.name }}
                    </CListGroupItem>
                  </CListGroup>
                </td>
              </template>
              <template #show_details="{ item }">
                <td>
                  <CButton :title="$t('ui.add_sites')" size="sm" class="mr-3" color="info" @click="edit(item)">
                    <CIcon name="cil-pencil" />
                  </CButton>
                  <CButton
                    :title="$t('ui.login_as')"
                    size="sm"
                    class="mr-3"
                    color="info"
                    :disabled="isSending"
                    @click="loginAs(item)"
                  >
                    <CIcon name="cil-arrow-thick-to-left" />
                  </CButton>
                </td>
              </template>
            </CDataTable>

            <CPagination
              v-if="isShowedPagination"
              :pages="totalPages"
              :active-page="activePage"
              size="sm"
              @update:activePage="onChangePage"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { GET_ADVERTISERS_LIST, GET_USER_TOKEN } from '@/store/action-types/admin';
import { GET_ROLES } from '@/store/action-types/roles';
import { limits } from '@/helpers/const';
import searchByTable from '@/helpers/search-by-table';
import MbnTableTools from '@/components/TableTools';
import MbnErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'Advertisers',
  components: {
    MbnTableTools,
    MbnErrorMessage,
  },
  data() {
    return {
      limit: this.$route.query.limit || limits[0].value,
      search: '',
      filtered: '',
      activePage: Number(this.$route.query.page || '1'),
    };
  },
  computed: {
    ...mapState({
      advertisersList: (state) => state.admin.advertisers.list,
      isLoading: (state) => state.admin.advertisers.isLoading,
      error: (state) => state.admin.advertisers.error,
      total: (state) => state.admin.advertisers.total,
      isSending: (state) => state.admin.userToken.isSending,
    }),
    tableItems() {
      return this.filtered ? this.filtered : this.advertisersList;
    },
    totalPages() {
      return Math.ceil(this.total / this.limit);
    },
    offset() {
      return this.activePage * this.limit - this.limit;
    },
    isShowedPagination() {
      return this.totalPages > 1 && !this.filtered;
    },
    fields() {
      return [
        { key: 'id', label: this.$t('profile.id') },
        { key: 'email', label: this.$t('profile.email') },
        { key: 'balance', label: this.$t('profile.balance') },
        { key: 'approvedSites', label: this.$t('profile.approved_sites') },
        { key: 'show_details', label: this.$t('ui.actions'), sorter: false },
      ];
    },
  },
  watch: {
    search(value) {
      this.filterData(value);
    },
    activePage() {
      this.fetchAdvertisersList();
    },
    limit() {
      this.fetchAdvertisersList();
    },
  },
  mounted() {
    this.fetchAdvertisersList();
  },
  methods: {
    ...mapActions('admin', [GET_ADVERTISERS_LIST, GET_USER_TOKEN]),
    ...mapActions('roles', [GET_ROLES]),
    resetSearch() {
      this.search = '';
    },
    edit(item, index, column) {
      //do not remove index param!
      if (column === 'show_details') {
        return;
      }

      this.$router.push({
        name: 'admin-advertisers-edit',
        params: { id: item.id },
      });
    },
    updateLimit(value) {
      this.limit = value;
      this.activePage = 1;
      this.$router.push({ query: { limit: this.limit } });
    },
    filterData(query) {
      this.filtered = searchByTable(query, this.advertisersList);
    },
    onChangePage(page) {
      this.activePage = page;
      this.$router.push({ query: { limit: this.limit, page } });
    },
    loginAs(user) {
      this.GET_USER_TOKEN({ userId: user.id })
        .then(() => {
          this.GET_ROLES();
        })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.auth_success'),
          });

          this.$router.push({ name: 'profile-advertiser' });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.auth_error'),
          });
        });
    },
    fetchAdvertisersList() {
      this.GET_ADVERTISERS_LIST({
        limit: this.limit,
        offset: this.offset,
      });
    },
  },
};
</script>
